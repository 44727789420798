import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || `Title`
	const posts = data.allMarkdownRemark.nodes
	const params = new URLSearchParams(location.search);
	const posts_category = posts.filter(x => x.frontmatter.category=== params.get("category"))

	if (posts.length === 0) {
		return (
		<Layout location={location} title={siteTitle}>
			<Seo title="HOME" />
			<Bio />
			<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8614409317427041"
				crossOrigin="anonymous"></script>
			<p>
			記事がありません。
			</p>
		</Layout>
		)
	}
	if (params.get("category") !== null){
		return(
		<Layout location={location} title={siteTitle}>
			<Seo title="HOME" />
			<Bio />
			<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8614409317427041"
				crossOrigin="anonymous"></script>
			<h1 className="">{params.get("category") }のカテゴリページ</h1>
			<ul className="breadcrumb__list" itemScope itemType="https://schema.org/BreadcrumbList">
				<li className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
					<a itemProp="item" href="/"><span itemProp="name">TOPページ</span></a>
					<meta itemProp="position" content="1" />
				</li>
				＞ 
				<li className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
					<span itemProp="name">{params.get("category")}</span>
					<meta itemProp="position" content="2" />
				</li>
			</ul>

			<ol style={{ listStyle: `none` }}>
				{posts_category.map(post => {
				const title = post.frontmatter.title || post.fields.slug
				const category_url = '?category=' + post.frontmatter.category
				const blogimg = post.frontmatter.blogimage.childImageSharp.gatsbyImageData.images.fallback.src

				return (
					<li key={post.fields.slug}>
					<div className="flex">
						<article
							className="post-list-item"
							itemScope
							itemType="http://schema.org/Article"
						>
							<header>
							<h2>
								<Link to={post.fields.slug} itemProp="url">
								<span itemProp="headline">{title}</span>
								</Link>
							</h2>
							<small>{post.frontmatter.date}　カテゴリ:<a href={category_url}>{post.frontmatter.category}</a></small>
							</header>
								<section>
								<p
									dangerouslySetInnerHTML={{
									__html: post.excerpt + '<a href=' + post.fields.slug + '>続き</a>',
									}}
									itemProp="description"
								/>
								</section>
						</article>
						<p className="list-img"><img src={blogimg} alt={title}></img></p>
					</div>
					</li>
				)
				}).filter(e => e)
				}
			</ol>
		</Layout>
		)

	}

	return (
		<Layout location={location} title={siteTitle}>
		<Seo title="HOME" />
		<Bio />
		<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8614409317427041"
			crossOrigin="anonymous"></script>
		<ol style={{ listStyle: `none` }}>
			{posts.map(post => {
			const title = post.frontmatter.title || post.fields.slug
			const category_url = '?category=' + post.frontmatter.category
			const blogimg = post.frontmatter.blogimage.childImageSharp.gatsbyImageData.images.fallback.src

			return (
				<li key={post.fields.slug}>
				<div className="flex">
					<article
						className="post-list-item"
						itemScope
						itemType="http://schema.org/Article"
					>
						<header>
						<h2>
							<Link to={post.fields.slug} itemProp="url">
							<span itemProp="headline">{title}</span>
							</Link>
						</h2>
						<small>{post.frontmatter.date}　カテゴリ:<a href={category_url}>{post.frontmatter.category}</a></small>
						</header>
						<section>
						<p
							dangerouslySetInnerHTML={{
							__html: post.excerpt + '<a href=' + post.fields.slug + '>続き</a>',
							}}
							itemProp="description"
						/>
						</section>
					</article>
					<p className="list-img">
						<Link to={post.fields.slug} itemProp="url">
							<img src={blogimg} alt={title}></img>
						</Link>
					</p>

				</div>
				</li>
			)
			})}
		</ol>
		</Layout>
	)
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY年MM月DD日")
          title
          description
          category
		  blogimage {
			childImageSharp  { gatsbyImageData(width:340,layout: FIXED) }
		  }
        }
      }
    }
  }
`
